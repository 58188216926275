import React from "react"
import GenericHero from "../GenericHero"
import SiteHeader from "../SiteHeader"
import TopImageContainer from "../TopImageContainer"
import SiteFooter from "../SiteFooter"
import LetsCoCreateSection from "../LetsCoCreateSection"
import content from "../../data/Content"
import Seo from "../Seo"

import InsightDetailSection from "./InsightDetailSection"

function getItemBySlug(data, slug) {
    data = data.filter(item => {return item.slug === slug});
    return data[0];
}

export default function InsightPage({slugParam, topImage}) {
  let page = "insight"
  let data = content(page)
  data = getItemBySlug(data.details, slugParam)
  
  return (
    <div className="content">
      <Seo page={data} />
      <TopImageContainer image={topImage}>
        <SiteHeader page={page} content={content("header")} />
        <GenericHero content={data} />
      </TopImageContainer>
      <InsightDetailSection content={data} />
      <LetsCoCreateSection content={content("lets-co-create-section")} />
      <SiteFooter content={content("footer")} />
    </div>
  )
}
