import React from "react"
import Css from "./Card.module.css"

const Card = props => {
  return (
    <div className={
        props.blueContainerStyle && props.blueContainerStyle === "fullWidth" 
        ? Css.BlueContainerFullWidth
        : props.blueContainerStyle === "fullHalfWidth" ? Css.BlueContainerFullHalfWidth : Css.BlueContainer} >
      {props.children()}
    </div>
  )
}

export default Card