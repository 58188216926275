import React from "react"
import Card from "../shared/Card/Card"
import Css from "./InsightDetailSection.module.css"

const InsightDetailSection = props => {

    let txts = []
    let cards = []
    let numbers = []
    let paragraphs = []
    let data = props.content
    if (data) {

        if (data.content["cards"]) {
            cards = data.content["cards"].map((item, idx) => {
    
                return <div key={idx} className={`${Css.CardItem}`} >
                    <Card blueContainerStyle="fullWidth">
                        {() => 
                            <div className={Css.CardContainer}>
                                <div className={Css.Card}>
                                    <div className={Css.CardInner}>
                                        {item["paragraphs"] ? item["paragraphs"].map((item, idx) => {

                                             return item["content"] ? <p key={idx} className={`${Css.CardParagraph}`}>{item["content"]}</p> : item["fullWidthImage"] ? 
                                            <div key={idx} className={Css.FullWidthContainer}>
                                                <img className={Css.FullWidthImage} src={require('../../images/' + item["fullWidthImage"])} alt=""></img> 
                                                <img className={Css.FloatOnTopImage} src={require('../../images/' + item["floatOnTopImage"])} alt=""></img> 
                                            </div>
                                            : item["bottomRightImage"] ?
                                                <img key={idx} className={Css.BottomRightImage} src={require('../../images/' + item["bottomRightImage"])} alt=""></img>
                                            : item["topLeftDetail"] ?
                                            <img key={idx} className={Css.TopLeftDetail} src={require('../../images/' + item["topLeftDetail"])} alt=""></img>
                                            : item["bottomRightDetail"] ?
                                            <img key={idx} className={Css.BottomRightDetail} src={require('../../images/' + item["bottomRightDetail"])} alt=""></img>
                                            : item["customBulletPoints"] ? item["customBulletPoints"].map((item, idx) => 
                                                
                                                <div key={idx} className={Css.BulletedListContainer}>
                                                    <div className={(item["customClass"] ? item["customClass"] : "") + " " + Css.BulletedListColumnLeft}>
                                                        <img className={Css.BulletedListImage} src={require('../../images/' + item["bulletImage"])} alt=""></img>
                                                    </div>
                                                    <div className={Css.BulletedListColumnRight}>
                                                        <span className={Css.BulletedListText}>{item["content"]}</span>
                                                    </div>
                                                </div>
                                                
                                            )
                                            :''

                                        }) : ''}
                                    </div>
                                </div>
                            </div>
                        }
                    </Card>
                </div>
            })
        }

        if (data.content["numbers"]) {
            numbers = data.content["numbers"].map((item_, idx_) => {
    
                txts = item_["txts"] ? item_["txts"].map((item, idx) => {
        
                    return (
                        <div key={idx}>
                            {item.title ? 
                            <p className={Css.Question}>
                                {item.title}
                            </p> 
                            : ''}
                            {item.text ? 
                            <p className={Css.Answer}>
                                {item.text}
                            </p>
                            : ''}
                            {item.image ?
                                <img src={require('../../images/' + item.image)} alt={item.title} className={Css.Image} />
                            : ''}
                        </div>
                    )
                }) : '';
    
                paragraphs = item_["paragraphs"] ? item_["paragraphs"].map((item, idx) => {
        
                    return (
                        <div key={idx}>
                            {item.icon ? 
                            <div className={Css.Tipdiv}>
                                <img src={require('../../images/tips.png')} alt={item.title} />
                                <p className={Css.TipText}>TIP #{item.icon}</p>
                            </div>
                            : ''}
                            {item.title ? 
                            <p className={Css.ParagraphsTitle}>
                                {item.title}
                            </p>
                            : ''}
                            <p className={Css.ParagraphsText} dangerouslySetInnerHTML={{__html: item.text}}></p>
                            {item.card ?
                                item.card.map((card, idcard) => {
        
                                    return (
                                        <div align="center">
                                            <img id={idcard} src={require('../../images/' + card.image)} alt={card.alt} className={Css.CardImage} />
                                        </div>
                                    )
                                })
                            : ''}
                            {item.image ?
                                <div align="center">
                                    <img src={require('../../images/' + item.image)} alt={item.title} className={Css.Image} />
                                </div>
                            : ''}
                        </div>
                    )
                }) : '';
        
                return (
                    <div key={idx_}>
                        {item_.no ? 
                        <p className={Css.No}>
                            {item_.no}
                        </p> 
                        : ''}
                        {item_.title ? 
                        <p className={Css.Title}>
                            {item_.title}
                        </p>
                        : ''}
                        {item_.question ? 
                        <p className={Css.Question}>
                            {item_.question}
                        </p> 
                        : ''}
                        {item_.answer ? 
                        <p className={Css.Answer}>
                            {item_.answer}
                        </p>
                        : ''}
                        {item_.txts ?
                            <div>{txts}</div>
                        : ''}
                        {item_.paragraphs ?
                            <div>{paragraphs}</div>
                        : ''}
                    </div>
                )
            })
        }

    }

    return (
        <div className={Css.InsightContentSection}>
            <p className={Css.Text} dangerouslySetInnerHTML={{__html: props.content.content.text}}></p>
            {data &&
                <div>{cards}{numbers}</div>
            }
        </div>
    )
}

export default InsightDetailSection